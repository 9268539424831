import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { ModuleWithProviders } from "@angular/core";
import { CheckAuthGuard } from './_guards/check-auth.guard';

export const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                redirectTo: '/auth/login',
                pathMatch: 'full',
                data: {
                    titleEn: 'Welcome to Akshaak',
                    titleAr: 'Welcome to Akshaak'
                }
            }, {
                path: 'welcome',
                canActivate: [CheckAuthGuard],
                loadChildren: 'app/welcome/welcome.module#WelcomeModule',
                data: {
                    titleEn: 'Welcome to Akshaak',
                    titleAr: 'Welcome to Akshaak'
                }
            },
            {
                path: 'coming-soon',
                loadChildren: 'app/coming-soon/coming-soon.module#ComingSoonModule',
                data: {
                    titleEn: 'Akshaak Coming Soon',
                    titleAr: 'Akshaak Coming Soon'
                }
            },
            {
                path: 'miscellaneous',
                loadChildren: 'app/+miscellaneous/miscellaneous.module#MiscellaneousModule',
                data: { pageTitle: 'Miscellaneous' }
            }, {
                path: 'merchant',
                loadChildren: 'app/+merchant/merchant.module#MerchantModule',
                data: { titleEn: 'merchant' }
            }
        ]
    },

    { path: 'auth', component: AuthLayoutComponent, loadChildren: 'app/+auth/auth.module#AuthModule' },

    { path: '**', redirectTo: 'miscellaneous/404' }

];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });
