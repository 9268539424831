
import {take, tap, scan,  map ,  mergeMapTo } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AngularFireMessaging } from '@angular/fire/messaging';
import * as firebase from 'firebase/app';
import { AppConfigService } from 'app/app-config.service';

interface QueryConfig {
    path: string, //  path to collection
    field: string, // field to orderBy
    limit: number, // limit per query
    reverse: boolean, // reverse order?
    prepend: boolean // prepend to source?
}

@Injectable()
export class ChatPaginationService {

   // private chatImageUploadUrl: string;
  //  private chatVideoUploadUrl: string;
    // Source data
    private _done = new BehaviorSubject(false);
    private _loading = new BehaviorSubject(false);
    private _data = new BehaviorSubject([]);

    private query: QueryConfig;

    // Observable data
    data: Observable<any>;
    pageNo: number = 0;
    done: Observable<boolean> = this._done.asObservable();
    loading: Observable<boolean> = this._loading.asObservable();


    constructor(private angularFirestore: AngularFirestore,
        private httpClient: HttpClient,
        private afMessaging: AngularFireMessaging,
        private _configuration: AppConfigService) {

//        this.chatImageUploadUrl = `${_configuration.getConfig().WEB_API}api/merchant/SaveChatBytes`;
      //  this.chatVideoUploadUrl = `${_configuration.getConfig().WEB_API}api/merchant/SaveChatAttachmentVideos`;
    }


    uploadImage(fileData) {
        let param: any = {};
        param.ImageBytes = fileData;

        return this.httpClient.post<any>(`${this._configuration.getConfig().WEB_API}api/merchant/SaveChatBytes`, param, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    const progress = Math.round(100 * event.loaded / event.total);
                    return { status: 'progress', message: progress };
                case HttpEventType.Response:
                    return event.body;
                default:
                    return `Unhandled event: ${event.type}`;
            }
        }));
    }

    uploadVideo(videoData) {
        const formData = new FormData();

        formData.append('file', videoData);

        return this.httpClient.post<any>(`${this._configuration.getConfig().WEB_API}api/merchant/SaveChatAttachmentVideos`, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    const progress = Math.round(100 * event.loaded / event.total);
                    return { status: 'progress', message: progress };
                case HttpEventType.Response:
                    return event.body;
                default:
                    return `Unhandled event: ${event.type}`;
            }
        }));
    }
    // Initial query sets options and defines the Observable
    // passing opts will override the defaults
    init(path: string, field: string, opts?: any) {
        this.query = {
            path,
            field,
            limit: 2,
            reverse: false,
            prepend: false,
            ...opts
        }

        const first = this.angularFirestore.collection(this.query.path, ref => {
            return ref
                .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                .limit(this.query.limit)
        })

        this.mapAndUpdate(first)

        // Create the observable array for consumption in components
        this.data = this._data.asObservable().pipe(
            scan((acc, val) => {
                this.pageNo++;
                return this.query.prepend ? val.concat(acc) : acc.concat(val)
            }))
    }

    clear() {
        this._data.next([]);
        this.pageNo = 0;
    }

    startConversation(docId, convDoc) {
        return this.angularFirestore.firestore.collection('chats')
            .doc(docId).set(convDoc, { merge: true });
    }


    // Retrieves additional data from firestore
    more() {
        const cursor = this.getCursor()

        const more = this.angularFirestore.collection(this.query.path, ref => {
            return ref
                .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                .limit(this.query.limit)
                .startAfter(cursor)
        })
        this.mapAndUpdate(more)
    }

    requestPermission(merchantId, lang) {
        let that = this;
        if (Notification.permission !== "denied")
            Notification.requestPermission((permission) => {
                if (Notification.permission == 'granted')
                    that.requestToken(that, merchantId, lang);
            });
        else
            that.requestToken(that, merchantId, lang);
        // let that = this,
        //     serviceWorkerRegistered = localStorage.getItem('akshaak_firebase_service_worker');
        // if ('serviceWorker' in navigator && !serviceWorkerRegistered) {
        //     navigator.serviceWorker.register('firebase-messaging-sw.js')
        //         .then(function (registration) {
        //             firebase.messaging().useServiceWorker(registration);
        //             localStorage.setItem('akshaak_firebase_service_worker', 'true');
        //             that.requestToken(that, merchantId);
        //         }, function (err) {
        //             console.log('ServiceWorker registration failed: ', err);
        //         });
        // } else {
        //     that.requestToken(that, merchantId);
        // }
    }

    requestToken(that, merchantId, lang) {
        that.afMessaging.requestPermission
            .pipe(mergeMapTo(that.afMessaging.tokenChanges))
            .subscribe(
                (token) => {
                    localStorage.setItem('admin_akshaak_firebase_token', token);
                  
                    that.angularFirestore.firestore.collection('users').doc(merchantId)
                        .set({
                            tokens: firebase.firestore.FieldValue.arrayUnion({
                                token,
                                languageCode: lang == 'en' ? 'en' : 'ar'
                            })
                        }, {
                                merge: true
                            })
                }, (error) => { console.error(error); },
            );
    }

    removeToken(merchantId, lang) {
        let token = localStorage.getItem('admin_akshaak_firebase_token');
        if (token)
            this.angularFirestore.firestore.collection('users').doc(merchantId)
                .set({
                    tokens: firebase.firestore.FieldValue.arrayRemove({
                        token,
                        languageCode: lang == 'en' ? 'en' : 'ar'
                    })
                }, {
                        merge: true
                    }).then(() => {
                        localStorage.removeItem('admin_akshaak_firebase_token');
                    })
    }


    // Determines the doc snapshot to paginate query 
    private getCursor() {
        const current = this._data.value
        if (current.length) {
            return this.query.prepend ? current[0].doc : current[current.length - 1].doc
        }
        return null
    }


    // Maps the snapshot to usable format the updates source
    private mapAndUpdate(col: AngularFirestoreCollection<any>) {

        if (this._done.value || this._loading.value) { return };

        // loading
        this._loading.next(true)

        // Map snapshot with doc ref (needed for cursor)
        return col.snapshotChanges().pipe(
            tap(arr => {
                let values = arr.map(snap => {
                    const data = snap.payload.doc.data()
                    const doc = snap.payload.doc
                    return { ...data, doc }
                })

                // If prepending, reverse the batch order
                values = this.query.prepend ? values.reverse() : values

                // update source with new values, done loading
                this._data.next(values)
                this._loading.next(false)

                // no more values, mark done
                if (!values.length) {
                    this._done.next(true)
                }
            }),
            take(1),)
            .subscribe()

    }

    /**
    * hook method when new notification received in foreground
    */
    receiveMessage() {
        this.afMessaging.messages.subscribe(
            (payload) => {
                if (Notification.permission == 'granted')
                    this.notify(payload);
            })
    }

    notify(notificationData) { //our function to be called on click
        try {
            let options = { //set options
                body: notificationData.notification.body,
                icon: "assets/icon/logo.png" //adding an icon
            }
            new Notification(notificationData.notification.title, options)
        } catch (e) {
            console.log(e)
        }
        // this._pushNotifications.create(notificationData.data.senderName + ' sent new message.', options)
        // .subscribe( //creates a notification
        //     res => console.log(res),
        //     err => console.log(err)
        // );
    }

}