import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LogoutComponent } from "./logout/logout.component";
import { LocalStorageService } from '../storage/storage.service';
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
  })
],
  declarations: [LogoutComponent],
  exports: [LogoutComponent],
  providers: [LocalStorageService]
})
export class UserModule { }
