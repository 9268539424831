
import { Injectable } from '@angular/core';
import { Configuration } from '../../../app.constant';

import 'rxjs/Rx';
import { Observable, Subject, BehaviorSubject, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'app/app-config.service';

@Injectable()
export class DashboardService {
   // private getDashboardDataUrl: string;
 
    constructor(private _http: HttpClient,
      //  private _configuration: Configuration,
        private _config:AppConfigService) {
//        this.getDashboardDataUrl = `${_configuration.getConfig().WEB_API}api/admin/AdminDashboard`;
    }
   
    getDashboardData(params: any): Observable<any> {
        return this._http.post<any>(this._config.getConfig().WEB_API+'api/admin/AdminDashboard', params);
    }

    markAsRead(params: any): Observable<any> {
        return this._http.post<any>(`${this._config.getConfig().WEB_API}Api/admin/markread`, params);
    }
    getCountryList():Observable<any>{
        return this._http.get<any>(`${this._config.getConfig().WEB_API}api/akshaak/ListCountry`);  
    }
    private messageSource = new BehaviorSubject<any>('');
    countryList = this.messageSource.asObservable();

    sendCountryList(countries) {
        //console.log("Message:",message);
        this.messageSource.next(countries)
    }
    getDashboardStat(params: any): Observable<any> {
        return this._http.post<any>(`${this._config.getConfig().WEB_API}api/admin/DashboardStat`, params);
    }
    getDashboardChanges(): Observable<any> {
        return this._http.get<any>(`${this._config.getConfig().WEB_API}api/admin/DashboardChangeRequest`);
    }
    getCitiesSale(): Observable<any> {
        return this._http.get<any>(`${this._config.getConfig().WEB_API}api/admin/CitySales`);
    }
    getTopStores(): Observable<any> {
        return this._http.get<any>(`${this._config.getConfig().WEB_API}api/admin/TopStores`);
    }
    getTopSellingProducts(): Observable<any> {
        return this._http.get<any>(`${this._config.getConfig().WEB_API}api/admin/TopSellingProducts`);
    }
    getWeeklySale(params: any): Observable<any> {
        return this._http.post<any>(`${this._config.getConfig().WEB_API}api/admin/TotalWeeklySale`,params);
    }

}