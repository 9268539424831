import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { retry } from 'rxjs/operators';
import { UserService } from '../user/user.service';


@Injectable()
export class HttpInterceptorMiddleWare implements HttpInterceptor {

    public pendingRequests: number = 0;

    constructor(private router: Router, private userService: UserService) {
    }

    public getToken(): string {
        let currentUser = this.userService.getCurrentUser();
        return currentUser ? currentUser.Token : null;
    }

    public getUserId(): string {
        let currentUser = this.userService.getCurrentUser();
        return currentUser ? currentUser.UserID : null;
    }

    public getLanguage() {
        return (localStorage.getItem("adminLangId")) ? localStorage.getItem("adminLangId") : "1";
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       // console.log(request);
        // check if requesting for the file within the application
        if (request.url.includes('assets')) return next.handle(request);

        let headers = {
            DeviceType: 'Admin',
            Platform: 'Windows',
            LangId: this.getLanguage()
        }

        // const token: string = this.getToken();
        // if (token) {
        //     headers['Authorization'] = 'Bearer ' + token;
        // }
        const UserId: string = this.getUserId();
        if (UserId)
            headers['UserId'] = UserId;

        request = request.clone({
            setHeaders: headers,
        });

        return next.handle(request);
    }

}