import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { I18nService } from 'app/shared/i18n';
import { LanguageEnum } from 'app/app.common';
import { OrdersService } from '../../+services/order.service';
import { BsModalService } from 'ngx-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UtilMethods } from 'app/shared/utils/util-methods';


@Component({
    selector: 'modal-reject-order',
    templateUrl: './reject-order.component.html',
    styleUrls: ['./reject-order.component.css']
})
export class RejectOrderComponent implements OnInit {

    public getDefaultLang: string;
    public LanguageEnum = LanguageEnum;
    public reasons: any = [];
    public selectedReason: string='';
    public detail_reason: string='';
    public closeEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(public bsModalRef: BsModalRef,
        public translate: TranslateService,
        private utilMethods: UtilMethods,
        private i18N: I18nService) { }

    ngOnInit() {
       // console.log(this.reasons)
        this.getDefaultLang = this.i18N.currentLanguage;
        this.i18N.getLanguageUpdates().subscribe((lang) => {
            this.getDefaultLang = lang.language;
        })
    }

    async closeModal(flag) {
        if (flag && !this.selectedReason) {
            let msg = await this.translate.get('please_select_some_reject_order_reason').toPromise<void>();
            this.utilMethods.notificationError(msg);
            return;
        }
        this.closeEvent.emit({ reason: this.selectedReason, rejectStatus: flag, detail_reason: this.detail_reason });
    }
}