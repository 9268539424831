
import {tap} from 'rxjs/operators';
//1. Import all dependencies
import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Configuration } from '../../../app.constant';
import { Observable } from 'rxjs';
import 'rxjs/Rx';
import { ServiceResponse } from '../../../core/models/service-response.model';
import { NewServiceResponse } from 'app/core/models/new-service-response.model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'app/app-config.service';

//2. The service class
@Injectable()
export class ProductService {
    //3. The local private variable for  storing the URL of the REST API.
   /*  private productAddUrl: string;
    private productAddProductUrl: string;
    private getProductDetailsUrl: string;
    private getProductDropDownUrl: string;
    private updatePublishedProductUrl: string;
    private getProductListUrl: string;
    private getProductListWithPaginationUrl: string;
    private updateProductUrl: string;
    private getProductInfoUrl: string;
    private updateProductStatusUrl: string;
    private getProductVariantUrl: string;
    private getVariantUrl: string;
    private addUpdateProductVariantUrl: string;
    private updateProductQuantity: string;
    private getProductVatUrl: string;
    private addUpdateVariant: string;
    private getSearchUrl: string;
    private deleteVariantUrl: string;
    private UpdateVariantStatusUrl: string;
    private UpdateVariantInventoryUrl: string;
    private getProductFilterListWithPaginationUrl: string;
    private GetProductImagesUrl : string; */
    /**
     * Product Section Common data
     */
    private dropDownData: any;


    private errorMsg: string = "Some error occurred !!";
    //4. Passsing the Http dependency to the constructor to access Http functions
    constructor(private _http: HttpClient, private _serviceResponse: ServiceResponse,
        private _configuration: AppConfigService) {

     //   this.productAddUrl = `${_configuration.getConfig().WEB_API}api/merchant/product/add`;
     //   this.productAddProductUrl = `${_configuration.getConfig().WEB_API}api/merchant/product/addProduct`;
    //    this.getProductDetailsUrl = `${_configuration.getConfig().WEB_API}api/merchant/GetProductDetail`;
      //  this.getProductDropDownUrl = `${_configuration.getConfig().WEB_API}api/merchant/GetProductDetailDropdowns`;
     //   this.getProductListUrl = `${_configuration.getConfig().WEB_API}api/merchant/product/list`;
     //   this.getProductVatUrl = `${_configuration.getConfig().WEB_API}api/merchant/store/GetVat`;
     //   this.getProductListWithPaginationUrl = `${_configuration.getConfig().WEB_API}api/admin/product/productlist`;
      //  this.updateProductUrl = `${_configuration.getConfig().WEB_API}api/merchant/product/edit`;
      //  this.getProductInfoUrl = `${_configuration.getConfig().WEB_API}api/merchant/product/productlistbyId`;
       // this.updateProductStatusUrl = `${_configuration.getConfig().WEB_API}api/merchant/product/Productstatus`;
     //   this.getProductVariantUrl = `${_configuration.getConfig().WEB_API}api/merchant/product-variant`;
     //   this.getVariantUrl = `${_configuration.getConfig().WEB_API}api/merchant/product/GetProductVariant`;
      //  this.addUpdateProductVariantUrl = `${_configuration.getConfig().WEB_API}api/merchant/product-variant/add-upd`;
      //  this.updateProductQuantity = `${_configuration.getConfig().WEB_API}api/merchant/product/ProductQuantity`;
      //  this.updatePublishedProductUrl = `${_configuration.getConfig().WEB_API}api/admin/product/Productstatus`;
      //  this.getSearchUrl = `${_configuration.getConfig().WEB_API}api/Customer/Filter/SearchKeyWord`;
     //   this.getProductFilterListWithPaginationUrl = `${_configuration.getConfig().WEB_API}api/merchant/product/filterproductlistNew`;
    //    this.addUpdateVariant = `${_configuration.getConfig().WEB_API}api/merchant/product/AddUpdateVariant`;
     //   this.deleteVariantUrl = `${_configuration.getConfig().WEB_API}api/merchant/product/DeleteProVariant`;
    //    this.UpdateVariantStatusUrl =  `${_configuration.getConfig().WEB_API}api/merchant/product/UpdateVariantStatus`;
     //   this.UpdateVariantInventoryUrl =  `${_configuration.getConfig().WEB_API}api/merchant/product/AddUpdateVariantInventory`;
    //    this.GetProductImagesUrl = `${_configuration.getConfig().WEB_API}api/Product/GetImagebyProductId`;

    }

    getProductFilterListWithPagination(params): Observable<any> {
        return this._http.post<any>(`${this._configuration.getConfig().WEB_API}api/merchant/product/filterproductlistNew`, params)
    }

    addProduct(product: any): Observable<ServiceResponse> {//service response
        return this._http.post<ServiceResponse>( `${this._configuration.getConfig().WEB_API}api/merchant/product/add`, product)
    }

    addProductNew(product: any): Observable<ServiceResponse> {//service response
        return this._http.post<ServiceResponse>( `${this._configuration.getConfig().WEB_API}api/merchant/product/addProduct`, product)
    }

    updateProduct(product: any): Observable<ServiceResponse> {
        return this._http.post<ServiceResponse>(`${this._configuration.getConfig().WEB_API}api/merchant/product/edit`, product)
    }

    getSearchList(params):Observable<any>{
        return this
        ._http.get<any>(`${this._configuration.getConfig().WEB_API}api/Customer/Filter/SearchKeyWord?KeyWord=` + params)
    }

    getProductDetail(): Observable<ServiceResponse> {
        return this
            ._http.get<ServiceResponse>(`${this._configuration.getConfig().WEB_API}api/merchant/product/productlistbyId`);
    }

    getProductDropDownData(): Observable<NewServiceResponse> {
        return this._http.get<NewServiceResponse>(`${this._configuration.getConfig().WEB_API}api/merchant/GetProductDetailDropdowns`).pipe(tap((res) => {
            if (res.StatusCode == 200) {
                this.dropDownData = res.Data;
            }
        }))
    }

    getProductVat(){
        return this._http.get<any>( `${this._configuration.getConfig().WEB_API}api/merchant/store/GetVat`)
    }
        
    getProductList(storeId: string): Observable<ServiceResponse> {
        return this._http.get<ServiceResponse>(`${this._configuration.getConfig().WEB_API}api/merchant/product/list/` + storeId)
    }

    getProductListWithPagination(params): Observable<NewServiceResponse> {
        return this._http.post<NewServiceResponse>( `${this._configuration.getConfig().WEB_API}api/admin/product/productlist`, params)
    }
    getFeaturedProductListWithPagination(params): Observable<NewServiceResponse> {
        return this._http.post<NewServiceResponse>(`${this._configuration.getConfig().WEB_API}api/admin/product/productlistSection`, params)
    }
    getProductSections(){
        return this._http.get<any>(`${this._configuration.getConfig().WEB_API}api/admin/GetSections`)
    }
    getProductCategories(){
        return this._http.get<any>(`${this._configuration.getConfig().WEB_API}api/admin/GetCategory`)
    }
    getProductInfo(productId: string): Observable<NewServiceResponse> {
        return this._http.get<NewServiceResponse>(`${this._configuration.getConfig().WEB_API}api/merchant/product/productlistbyId?ProductId=` + productId)
    }

    updateProductStatus(params): Observable<ServiceResponse> {
        return this._http.post<ServiceResponse>(`${this._configuration.getConfig().WEB_API}api/merchant/product/Productstatus`, params)
    }

    updatePublishProduct(params): Observable<NewServiceResponse> {
        return this._http.post<NewServiceResponse>(`${this._configuration.getConfig().WEB_API}api/admin/product/Productstatus`, params)
    }
    updateFeatureProduct(params): Observable<NewServiceResponse> {
        return this._http.post<NewServiceResponse>(`${this._configuration.getConfig().WEB_API}api/admin/product/UpdateSection`, params)
    }
    getProductVariantById(productId: string): Observable<ServiceResponse> {
        return this._http.get<ServiceResponse>(`${this._configuration.getConfig().WEB_API}api/merchant/product-variant/` + productId)
    }

    addUpdateProductVariant(productVariant: any): Observable<ServiceResponse> {
        return this._http.post<ServiceResponse>(`${this._configuration.getConfig().WEB_API}api/merchant/product-variant/add-upd`, JSON.stringify(productVariant))
    }

    updateProductVariant(productVariant: any): Observable<ServiceResponse> {
        return this._http.post<ServiceResponse>(`${this._configuration.getConfig().WEB_API}api/merchant/product/ProductQuantity`, productVariant)
    }
    getVariantById(productId: string): Observable<ServiceResponse> {
        return this._http.get<ServiceResponse>(`${this._configuration.getConfig().WEB_API}api/merchant/product/GetProductVariant?ProductId=` + productId)
    }

    addProductVariant(variant: any): Observable<ServiceResponse> {
        return this._http.post<ServiceResponse>(`${this._configuration.getConfig().WEB_API}api/merchant/product/AddUpdateVariant`, variant)
    }

    deleteVariant(variant: any): Observable<any> {
        return this._http.post<any>(`${this._configuration.getConfig().WEB_API}api/merchant/product/DeleteProVariant` , variant);
    }

    
    UpdateVariantStatus(variant: any): Observable<any> {
        return this._http.post<any>(`${this._configuration.getConfig().WEB_API}api/merchant/product/UpdateVariantStatus` , variant);
    }

    UpdateVariantInventory(variant: any): Observable<any> {
        return this._http.post<any>( `${this._configuration.getConfig().WEB_API}api/merchant/product/AddUpdateVariantInventory` , variant);
    }
    GetProductImages(productId : any) : Observable<any> {
        return this._http.get<ServiceResponse>( `${this._configuration.getConfig().WEB_API}api/Product/GetImagebyProductId?ProId=` + productId)
        
    }

    GetProductChanges(params: any): Observable<any> {
        return this._http.post<any>(`${this._configuration.getConfig().WEB_API}api/admin/Viewallchanges`,params)

    }
    SaveComment(params:any): Observable<any> {
        return this._http.post<any>(`${this._configuration.getConfig().WEB_API}api/Akshaak/SaveProductsComments`,params)

    }

    GetProductComment(productId : any) : Observable<any> {
        return this._http.get<ServiceResponse>(`${this._configuration.getConfig().WEB_API}api/Akshaak/GetProductCommentByPrdouctId?ProductId=` + productId)
        
    }
    ProductImages(productId : any) : Observable<any> {
        return this._http.get<ServiceResponse>(`${this._configuration.getConfig().WEB_API}api/admin/DownloadProductImages?ProductId=` + productId)       
    }
    ProductImagesByStore(StoreId : any, Status:any) : Observable<any> {
        return this._http.get<ServiceResponse>(`${this._configuration.getConfig().WEB_API}api/admin/DownloadProductImagesbyStoreid?StoreId=` + StoreId+`&Status=`+Status)       
    }
    getSubCategoryByCatId(Id):Observable<any>{
        return this._http.get<any>(`${this._configuration.getConfig().WEB_API}api/admin/GetSubCategoryByCatId?Id=`+Id);  
    }   
    productListExport(params): Observable<NewServiceResponse> {
        return this._http.post<NewServiceResponse>(`${this._configuration.getConfig().WEB_API}api/admin/product/productlistExport`, params)
    }
    getAkshaakSpecialList(){
        return this._http.get<any>(`${this._configuration.getConfig().WEB_API}api/product/AkshaakSpecialList`)
    }
    getAkshaakGroupList(){
        return this._http.get<any>(`${this._configuration.getConfig().WEB_API}api/product/AkshaakGroupList`)
    }
    getAkshaakBannerList(){
        return this._http.get<any>(`${this._configuration.getConfig().WEB_API}api/product/AkshaakBannerList`)
    }
    SaveAkshaakSpecialGroup(params : any) : Observable<any> {
        return this._http.post<any>(`${this._configuration.getConfig().WEB_API}api/product/SaveAkshaakSpecialGroup`,params)
    }
    SaveAkshaakGroup(params : any) : Observable<any> {
        return this._http.post<any>(`${this._configuration.getConfig().WEB_API}api/product/SaveAkshaakGroup`,params)
    }

}