// jQuery
declare var jQuery: any;

// RxJS



// import 'rxjs/add/operator/mergeMap';






// Smartadmin Dependencies
window['jQuery'] = require('jquery');
require('intersection-observer');
window['$'] = window['jQuery'];
import 'jquery-ui-npm/jquery-ui.min.js'

// require('bootstrap/js/tooltip.js'); // required for X-editable
// require('bootstrap/js/popover.js'); // required for X-editable
// require('bootstrap/js/dropdown.js'); // required for bootstrap-colorpicker
// require('bootstrap/js/tab.js'); //
// require('bootstrap/js/modal.js'); //

window['moment'] = require('moment');

require('smartadmin-plugins/notification/SmartNotification.min.js');

