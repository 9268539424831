export class User {
    constructor() {

    }

    public UserID: string;
    public MerchantID: string;
    public StoreID: string;
    public CatID: string;
    public FirstName: string;
    public LastName: string;
    public Email: string;
    public PhoneNo: string;
    public IsVerifiedOTP: boolean;
    public ProfileImage: string;
    public Token: string;
    public LicSourceid:string;
}
