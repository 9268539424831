import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CollapseMenuComponent } from "./collapse-menu/collapse-menu.component";
import { FullScreenComponent } from "./full-screen/full-screen.component";
import { HeaderComponent } from "./header.component";

import { UtilsModule } from "../../utils/utils.module";
import { I18nModule } from "../../i18n/i18n.module";
import { UserModule } from "../../user/user.module";
import { BsDropdownModule } from "ngx-bootstrap";
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BsDropdownModule,
    UtilsModule,
    I18nModule, UserModule
  ],
  declarations: [
    HeaderComponent,
    FullScreenComponent,
    CollapseMenuComponent
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
