import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, NgZone, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { UserService } from '../../shared/user';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { UtilMethods } from '../../shared/utils/util-methods';
import { ChatPaginationService } from './chat.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/messaging';
import * as moment from 'moment';
import { HostListener } from "@angular/core";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from 'app/shared/layout/navigation/navigation.service';
import { LanguageEnum } from '../../app.common';
import { I18nService } from 'app/shared/i18n';

@AutoUnsubscribe()
@Component({
    selector: 'app-chats',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.css']
})

export class ChatsComponent implements OnInit, OnDestroy {

    @ViewChild('messageContainer') messageContainer: ElementRef;
    @ViewChild('messageInnerContainer') messageInnerContainer: ElementRef;
    @ViewChild('conversationsContainer') conversationsContainer: ElementRef;
    @Input('startConversationId') startConversationId;
    public lastScrollTop: number = 0;
    public lastHeight: number = 0;
    public userDetails: any;
    private LanguageEnum = LanguageEnum;
    public getDefaultLang: string;
    public subStoreID: Subscription;
    public merchantId: string;
    public textMessage: string;
    public currentDocId: string;
    public messages = [];
    public allMessages = [];
    public searchFilter: string;
    public consumerId: string = 'consumer_id';
    public selectedConversation: any;
    public chatLoading: boolean;
    public infiniteLoading: boolean;
    public conversationsLoading: boolean = true;
    public setupFailed: boolean = false;
    public LIST_CONVERSATIONS: any = [];
    public LIST_CONVERSATIONS_BACKUP: any = [];
    public noMoreMessages: boolean = false;
    public firstPageLoaded: boolean = false;
    public lastMessageCursor: any;
    public currentConversationMessagesListener: any;
    public convsersationListnerSubscriber: any;
    public scrWidth: any;


    @HostListener('window:resize', ['$event'])
    toggleScreen(event?) {
        this.scrWidth = window.innerWidth;
        let contactsBody: HTMLElement = document.getElementById('contactsBody');
        let chatsBody: HTMLElement = document.getElementById('chatsBody');
        if (this.selectedConversation && this.scrWidth < 679) {
            chatsBody.setAttribute("style", "display: block; padding-left: 11px;");
            contactsBody.setAttribute("style", "display: none");
        }
        else {
            contactsBody.setAttribute("style", "display: block");
            chatsBody.setAttribute("style", "display: block");
        }

    }


    constructor(private _userService: UserService,
        private utilMethods: UtilMethods,
        private cdRef: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
        private afMessaging: AngularFireMessaging,
        public afAuth: AngularFireAuth,
        public chatService: ChatPaginationService,
        private i18N: I18nService,
        private fns: AngularFireFunctions,
        private angularRealTimeDatabase: AngularFireDatabase,
        private angularFirestore: AngularFirestore,
        private router: Router,
        private navigationService: NavigationService) {

        this.userDetails = this._userService.getCurrentUser();
        this.merchantId = this.userDetails.StoreID;
    }


    ngOnInit() {

        // this.tsxtNotifications('');
        this.getDefaultLang = this.i18N.currentLanguage;
        this.i18N.getLanguageUpdates().subscribe((lang) => {
            this.getDefaultLang = lang.language;
            this.chatService.removeToken(this.merchantId, this.getDefaultLang == 'en' ? 'ar' : 'en');
            this.chatService.requestPermission(this.merchantId, this.getDefaultLang);
        })

        this.signinAnonymously();

        this._activatedRoute.params
            .subscribe(params => {
                this.consumerId = params["consumerId"];
                if (this.consumerId)
                    this.startConversationId = this.merchantId + '_' + this.consumerId;
            }, (err) => {
            });

        this.afMessaging.messaging
            .subscribe((_messaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            })

        if (!localStorage.getItem('admin_akshaak_firebase_token'))
            this.chatService.requestPermission(this.merchantId, this.getDefaultLang);
    }

    async signinAnonymously() {
        try {
            let credentials = await this.afAuth.auth.signInAnonymously();
            if (credentials && credentials.user) {
                this.converstationListener();
                // this.handlePresence();
            }
        } catch (error) {
            console.log(error);
        }
    }

    parseConversation(doc, self) {
        let doesExist = self.isAlreadyAddedIntoList(doc);
        if (!doesExist) {
            let convo = doc.data();
            convo.id = doc.id;
            self.LIST_CONVERSATIONS.push(convo);
        } else {
            doesExist = doc.data();
        }
        self.LIST_CONVERSATIONS_BACKUP = self.LIST_CONVERSATIONS;
        this.sortConversations();
        /**
         * Starting conversation if it's selected from 
         * other module.
         */
        if (self.startConversationId) {
            let conv = self.LIST_CONVERSATIONS.filter(x => x.id == self.startConversationId);
            if (conv && conv.length > 0)
                self.chooseConversation(conv[0]);
            this.startConversationId = '';
        }
    }

    async handleActivitySubscription(snapshot, counter, self) {
        const initialLoad = counter === 1;
        self.conversationsLoading = false;
        snapshot.docChanges().forEach((change) => {
            if (initialLoad) {
                self.parseConversation(change.doc, self);
            } else {
                // console.log(change.doc);
                var source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                if (source == 'Server')
                    self.parseConversation(change.doc, self);
            }
        });
    }

    async converstationListener() {

        const handleActivitySubscriptionWithCounter =
            this.createFnCounter(this.handleActivitySubscription, 0, this);

        this.convsersationListnerSubscriber = this.angularFirestore.firestore.collection('chats')
            // .where('merchantId', '==', this.merchantId)
            .orderBy('updatedAt', 'desc')
            .onSnapshot(handleActivitySubscriptionWithCounter);
    }

    searchConversation(value) {
        if (!value) {
            this.LIST_CONVERSATIONS = this.LIST_CONVERSATIONS_BACKUP;
        } else {
            this.LIST_CONVERSATIONS = Object.assign([], this.LIST_CONVERSATIONS).filter(
                item => (item.consumerName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                    item.consumerNameAr.toLowerCase().indexOf(value.toLowerCase()) > -1)
            );
        }
    }

    checkIfOtherUserTypingToShow() {
        if (!this.selectedConversation || !this.selectedConversation.consumerTypingStartTime)
            return false;
        if (!this.chatLoading && this.selectedConversation &&
            this.selectedConversation.consumerTyping &&
            (moment(new Date()).diff(moment(this.selectedConversation.consumerTypingStartTime.seconds * 1000)) > 0 &&
                moment(new Date()).diff(moment(this.selectedConversation.consumerTypingStartTime.seconds * 1000)) < 60000))
            return true;
        else
            return false;
    }

    isAlreadyAddedIntoList(conversationNewOrUpdate) {
        let updateItem = this.LIST_CONVERSATIONS.find(this.findIndexToUpdate, conversationNewOrUpdate.id);
        if (updateItem) {
            let index = this.LIST_CONVERSATIONS.indexOf(updateItem);
            let newConvo = conversationNewOrUpdate.data();
            newConvo['id'] = conversationNewOrUpdate.id;
            if (this.LIST_CONVERSATIONS[index].consumerLogo != newConvo.consumerLogo) {
                this.LIST_CONVERSATIONS[index] = newConvo;
            } else {
                this.LIST_CONVERSATIONS[index].consumerName = newConvo.consumerName;
                this.LIST_CONVERSATIONS[index].consumerNameAr = newConvo.consumerNameAr;
                this.LIST_CONVERSATIONS[index].updatedAt = newConvo.updatedAt;
                this.LIST_CONVERSATIONS[index].lastMessage = newConvo.lastMessage;
                this.LIST_CONVERSATIONS[index].messageType = newConvo.messageType;
                this.LIST_CONVERSATIONS[index].lastMessageFrom = newConvo.lastMessageFrom;
                this.LIST_CONVERSATIONS[index].merchantTextSeen = newConvo.merchantTextSeen;
                this.LIST_CONVERSATIONS[index].consumerTextSeen = newConvo.merchantTextSeen;
                this.LIST_CONVERSATIONS[index].consumerTyping = newConvo.merchantTextSeen;
                if (this.LIST_CONVERSATIONS[index].consumerTyping && this.selectedConversation && this.selectedConversation.id == newConvo.id)
                    this.scrollToBottom();
                this.cdRef.detectChanges();
            }

            if (this.selectedConversation && this.selectedConversation.id == newConvo.id)
                this.selectedConversation = newConvo;
            return true;
        }
        return false;
    }

    findIndexToUpdate(newItem) {
        return newItem.id === this;
    }

    typingNow() {
        this.angularFirestore.collection('chats').doc(this.currentDocId).update({ merchantTyping: true, merchantTypingStartTime: firebase.firestore.FieldValue.serverTimestamp() });
    }

    finishedTyping() {
        this.angularFirestore.collection('chats').doc(this.currentDocId).update({ merchantTyping: false });
    }

    async sendMessage() {
        if (!this.textMessage || this.textMessage == '') {
            return;
        }
        let newTextMsg = {
            sender: this.merchantId,
            recipient: this.consumerId,
            textMessage: this.textMessage,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            messageType: 0,
            attachmentUrl: '',
            consumerTextSeen: false,
            merchantTextSeen: true
        };
        let newMsg = await this.angularFirestore.collection('chats').doc(this.currentDocId).collection('messages')
            .add(newTextMsg);
        newTextMsg['id'] = newMsg.id;
        /**
        * Updating local selected conversation
        */
        let updateItem = this.LIST_CONVERSATIONS.find(this.findIndexToUpdate, this.selectedConversation.id);
        let indexOfCurrentConv = this.LIST_CONVERSATIONS.findIndex(this.findIndexToUpdate, this.selectedConversation.id);
        updateItem.lastMessageFrom = this.merchantId;
        updateItem.messageType = 0;
        updateItem.lastMessage = this.textMessage;
        // updateItem.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
        if (this.LIST_CONVERSATIONS[0].id != updateItem.id)
            this.moveConversationToTop(indexOfCurrentConv);

        this.textMessage = '';

        newTextMsg['MINE'] = true;
        newTextMsg['sent_time'] = new Date().getTime();
        newTextMsg['date'] = moment(new Date(newTextMsg['sent_time'])).format("D MMMM, YYYY");
        this.messages.push(newTextMsg);
        this.addMessageIntoRelevantGroup(newTextMsg, false);
        // this.groupByDate(this.messages);
        this.scrollToBottom();
        this.textSeenUpdate();
    }

    composeFileMessage(link, messageType, thumbnail) {
        let newTextMsg = {
            sender: this.merchantId,
            recipient: this.consumerId,
            textMessage: '',
            consumerTextSeen: false,
            merchantTextSeen: true,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            messageType: messageType,
            attachmentUrl: ''
        };
        if (messageType == 1)
            newTextMsg['base64'] = link;
        else {
            newTextMsg['uploading'] = true;
            newTextMsg['bufferUrl'] = link;
        }
        this.textMessage = '';
        newTextMsg['MINE'] = true;
        newTextMsg['sent_time'] = new Date().getTime();
        newTextMsg['date'] = moment(new Date(newTextMsg['sent_time'])).format("D MMMM, YYYY");
        this.messages.push(newTextMsg);

        /**
         * Updating local selected conversation
         */
        let updateItem = this.LIST_CONVERSATIONS.find(this.findIndexToUpdate, this.selectedConversation.id);
        let indexOfCurrentConv = this.LIST_CONVERSATIONS.findIndex(this.findIndexToUpdate, this.selectedConversation.id);
        updateItem.lastMessageFrom = this.merchantId;
        updateItem.messageType = messageType;
        updateItem.lastMessage = this.textMessage;
        // updateItem.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
        this.moveConversationToTop(indexOfCurrentConv);

        this.addMessageIntoRelevantGroup(newTextMsg, false);
        this.scrollToBottom();
        return newTextMsg;
    }

    async fileChangeEvent(event: any) {
        if (event.target.files && event.target.files[0].type.indexOf('video') >= 0) {
            let msgObj = this.composeFileMessage(window.URL.createObjectURL(event.target.files[0]), 2, '');
            let response: any = await this.chatService.uploadVideo(event.target.files[0]).toPromise();
            if (response.IsSuccess) {
                msgObj['thumbnailUrl'] = response.Data.ImageThumbnail;
                msgObj['attachmentUrl'] = response.Data.VideoPath;
                msgObj['uploading'] = false;
                this.postFileUpload(msgObj, response.Data.VideoPath);
            }
        } else if (event.target.files) {
            const fr = new FileReader();
            fr.onloadend = (loadEvent) => {
                let msgObj = this.composeFileMessage(fr.result, 1, null);
                this.uploadAndSendThen(fr.result, msgObj);
            };
            fr.readAsDataURL(event.target.files[0]);
        }
    }

    async uploadAndSendThen(base64, msgObj) {
        let response: any = await this.chatService.uploadImage(base64).toPromise();
        if (response && response.StatusCode == 200) {
            this.postFileUpload(msgObj, response.Result);
        }
    }

    async postFileUpload(msgObj, attachmentUrl) {
        let tempObj: any = {};
        Object.assign(tempObj, msgObj);
        if (tempObj.base64)
            delete tempObj.base64;
        if (tempObj.bufferUrl)
            delete tempObj.bufferUrl;

        delete tempObj.MINE;
        delete tempObj.uploading;
        delete tempObj.sent_time;
        if (tempObj.thumbnail)
            delete tempObj.thumbnail;
        tempObj.attachmentUrl = attachmentUrl;
        let newMsg = await this.angularFirestore.collection('chats').doc(this.currentDocId).collection('messages')
            .add(tempObj);
        msgObj.id = newMsg.id;
        this.textSeenUpdate();
        this.scrollToBottom();
    }

    textSeenUpdate() {
        try {
            const callable = this.fns.functions.httpsCallable('incrementMessageUnseen');
            callable({
                conversationId: this.selectedConversation.id,
                userType: 'merchant',
                receiverId: this.consumerId
            })
        } catch (e) {
            console.log(e)
        }
    }


    loadPreviousChat() {
        this.chatLoading = true;
        this.messages = [];

        let ignoreFirstPage = false, lastDoc, that = this;
        let query = this.angularFirestore.firestore.collection('chats')
            .doc(this.currentDocId)
            .collection('messages')
            .orderBy('createdAt', 'desc')
            .limit(5);

        this.currentConversationMessagesListener = query.onSnapshot(querySnapshot => {
            if (!ignoreFirstPage) {
                ignoreFirstPage = true;
                return;
            }

            querySnapshot.docChanges().forEach((change) => {
                let message = change.doc.data();
                var source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                /**
                 * When document is written properly
                 */
                if (source == 'Server') {
                    if (change.type === 'added' && message.sender != that.merchantId) {
                        message['sent_time'] = (message.createdAt.seconds * 1000);
                        message['date'] = moment(new Date(message.sent_time)).format("D MMMM, YYYY");
                        message['id'] = change.doc.id;
                        that.messages.push(message);
                        // lastDoc = change.doc;
                        // that.lastMessageCursor = lastDoc;
                        that.addMessageIntoRelevantGroup(message, false);
                        that.setSeenAllNewUnseenMessages([message.id]);
                        that.scrollToBottom();
                    } else if (change.type == 'modified' && message.sender == that.merchantId && message.consumerTextSeen) {
                        let index = that.messages.findIndex(that.findIndexToUpdate, change.doc.id);
                        message['sent_time'] = (message.createdAt.seconds * 1000);
                        message['date'] = moment(new Date(message.sent_time)).format("D MMMM, YYYY");
                        message['id'] = change.doc.id;
                        message['MINE'] = true;
                        that.messages[index] = message;
                        that.addMessageIntoRelevantGroup(message, true);
                    }
                }
            })
        }, (error) => {
            console.log(error);
        })

        let firstPageQuery = this.angularFirestore.firestore.collection('chats')
            .doc(this.currentDocId)
            .collection('messages')
            .orderBy('createdAt', 'desc')
            .limit(10);

        firstPageQuery.get().then((snapshot) => {
            if (snapshot.size < 10)
                this.noMoreMessages = true;
            this.loadMessages(snapshot, false, false);
        }).catch((e) => {
            console.log(e);
        })
    }

    loadMessages(querySnapshot, append, scrollMaintain) {
        let lastDoc, newMessagesCount = 0,
            that = this,
            unseenMessages = [];
        if (!querySnapshot.empty) {
            querySnapshot.docs.forEach((doc) => {
                let message = doc.data();
                lastDoc = doc;
                if (message.sender == this.merchantId)
                    message['MINE'] = true;
                message['id'] = doc.id;
                message['sent_time'] = (message.createdAt.seconds * 1000);
                message['date'] = moment(new Date(message.sent_time)).format("D MMMM, YYYY");
                if (!message.merchantTextSeen) {
                    unseenMessages.push(message.id);
                }
                if (append)
                    this.messages.push(message);
                else {
                    this.messages.splice(0, 0, message);
                }
                newMessagesCount++;
            })
            // Clear Unseen Messages
            if (unseenMessages.length > 0)
                this.setSeenAllNewUnseenMessages(unseenMessages);
            this.groupByDate(this.messages);
            this.lastMessageCursor = lastDoc;
            if (!this.firstPageLoaded)
                this.scrollToBottom();
            this.firstPageLoaded = true;
            this.chatLoading = false;
            this.infiniteLoading = false;
            this.cdRef.detectChanges();
            setTimeout(() => {
                if (scrollMaintain) {
                    let newHeight = that.messageInnerContainer.nativeElement.clientHeight;
                    that.messageContainer.nativeElement.scrollTo({ top: that.lastScrollTop + (newHeight - that.lastHeight) });
                }
            }, 500);
        } else {
            this.chatLoading = false;
            this.infiniteLoading = false;
            this.cdRef.detectChanges();
        }
    }

    setSeenAllNewUnseenMessages(messageIds) {
        try {
            const callable = this.fns.functions.httpsCallable('clearUnseenMessages');
            callable({
                conversationId: this.selectedConversation.id,
                userType: 'merchant',
                userId: this.merchantId,
                messageIds: messageIds.join()
            })
        } catch (e) {
            console.log(e)
        }
    }

    onScrollUp() {
        let scrollTop = this.messageContainer.nativeElement.scrollTop;
        let height;
        if (this.messageInnerContainer)
            height = this.messageInnerContainer.nativeElement.clientHeight;
        if (scrollTop < this.lastScrollTop && scrollTop < 100 && !this.chatLoading && !this.noMoreMessages) {
            this.chatLoading = true;
            if (!this.noMoreMessages && this.selectedConversation) {
                this.infiniteLoading = true;
                let query = this.angularFirestore.firestore.collection('chats')
                    .doc(this.currentDocId)
                    .collection('messages')
                    .orderBy('createdAt', 'desc')
                    .startAfter(this.lastMessageCursor)
                    .limit(10);

                query.get().then((snapshot) => {
                    if (snapshot.size < 10)
                        this.noMoreMessages = true;
                    this.loadMessages(snapshot, false, true);
                })
            }
        }
        if (!this.infiniteLoading) {
            this.lastScrollTop = scrollTop;
            this.lastHeight = height;
        }
    }

    tsxtNotifications(token) {
        try {
            const callable = this.fns.functions.httpsCallable('sendFcmPushNotificationTest');
            callable({
                tokens: ['eEosqHDM_sA:APA91bHtNW8LYyJ4z1grBERjL0Ts0KKUEQca0aSzbJOgawBtgqBmJA905r5XxBPs0acXVkBcl_5sfesa0jVesHZIsuG6B1TaDdmUrY0rza9LL5ysRZIvSXP5Ijh4KIeRoh2h6U-yNxsK'],
                sender: this.merchantId,
                senderName: 'Muhammad Bilal'
            })
        } catch (e) {
            console.log(e)
        }
    }

    chooseConversation(conversation) {
        if (this.selectedConversation && this.selectedConversation.id == conversation.id)
            return;

        // Set Last scroll top to 0
        this.lastScrollTop = 0;
        /**
         * clear last conversation messages listerner
         */
        if (this.currentConversationMessagesListener)
            this.currentConversationMessagesListener();

        // if (this.conversationsContainer)
        //     this.conversationsContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
        this.messages = [];
        this.allMessages = [];
        this.noMoreMessages = false;
        this.selectedConversation = conversation;
        this.toggleScreen();
        this.consumerId = conversation.consumerId;
        this.currentDocId = this.merchantId + '_' + this.consumerId;
        this.firstPageLoaded = false;
        this.loadPreviousChat();
    }

    closeConversation() {
        this.selectedConversation = null;
        this.toggleScreen();
        this.textMessage = '';
        this.chatService.data = null;
        this.chatService.clear();
        this.messages = [];
        if (this.currentConversationMessagesListener)
            this.currentConversationMessagesListener();
    }

    scrollToBottom() {
        setTimeout(() => {
            try {
                let totalBottom = this.messageContainer.nativeElement.scrollHeight + 570;
                this.messageContainer.nativeElement.scrollTo({ top: totalBottom, behavior: 'smooth' });
            } catch (e) { }
        }, 500);
    }

    ngOnDestroy() {
        if (this.convsersationListnerSubscriber)
            this.convsersationListnerSubscriber();
        if (this.currentConversationMessagesListener)
            this.currentConversationMessagesListener();
    }

    addMessageIntoRelevantGroup(message, updatePreviousMessage?) {
        let msgGroup = this.allMessages.find(x => x.key == message.date);
        if (!msgGroup) {
            this.allMessages.push({
                key: message.date,
                value: [message]
            })
        } else {
            if (!updatePreviousMessage) {
                if (msgGroup.key == message.date) {
                    msgGroup.value.push(message);
                }
            } else {
                /**
                 * Updating message with specific property
                 * Only consumerTextSeen can be updated.
                 */
                let msgIndex = msgGroup.value.findIndex(x => x.id == message.id);
                if (msgIndex >= 0) {
                    msgGroup.value[msgIndex]['consumerTextSeen'] = message.consumerTextSeen;
                    this.cdRef.detectChanges();
                }
            }
        }
    }

    groupByDate(collection: any) {
        if (!collection) {
            return null;
        }
        const groupedCollection = collection.reduce((previous, current) => {
            if (!previous[current['date']]) {
                previous[current['date']] = [current];
            } else {
                previous[current['date']].push(current);
            }

            return previous;
        }, {});
        // this will return an array of objects, each object containing a group of objects
        this.allMessages = Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
    }

    sortConversations() {
        if (!this.LIST_CONVERSATIONS)
            return;
        this.LIST_CONVERSATIONS.sort((a, b) => {
            a = new Date(a.updatedAt.seconds);
            b = new Date(b.updatedAt.seconds);
            return a > b ? -1 : a < b ? 1 : 0;
        });
        this.cdRef.detectChanges();
    }

    moveConversationToTop(fromIndex) {
        var element = this.LIST_CONVERSATIONS[fromIndex];
        this.LIST_CONVERSATIONS.splice(fromIndex, 1);
        this.LIST_CONVERSATIONS.splice(0, 0, element);
    }


    createFnCounter(fn, invokeBeforeExecution, self) {
        let count = 0;
        return (args) => {
            count++;
            if (count <= invokeBeforeExecution) {
                return true;
            } else {
                return fn(args, count, self);
            }
        }
    }

    handlePresence() {
        // Fetch the current user's ID from Firebase Authentication.
        var uid = this.afAuth.auth.currentUser.uid;

        // Create a reference to this user's specific status node.
        // This is where we will store data about being online/offline.
        var userStatusDatabaseRef = this.angularRealTimeDatabase.database.ref('/status/' + uid);

        // We'll create two constants which we will write to 
        // the Realtime database when this device is offline
        // or online.
        var isOfflineForDatabase = {
            state: 'offline',
            last_changed: firebase.database.ServerValue.TIMESTAMP,
        };

        var isOnlineForDatabase = {
            state: 'online',
            last_changed: firebase.database.ServerValue.TIMESTAMP,
        };

        // Create a reference to the special '.info/connected' path in 
        // Realtime Database. This path returns `true` when connected
        // and `false` when disconnected.
        this.angularRealTimeDatabase.database.ref('.info/connected').on('value', function (snapshot) {
            // If we're not currently connected, don't do anything.
            if (snapshot.val() == false) {
                return;
            };

            // If we are currently connected, then use the 'onDisconnect()' 
            // method to add a set which will only trigger once this 
            // client has disconnected by closing the app, 
            // losing internet, or any other means.
            userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
                // The promise returned from .onDisconnect().set() will
                // resolve as soon as the server acknowledges the onDisconnect() 
                // request, NOT once we've actually disconnected:
                // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

                // We can now safely set ourselves as 'online' knowing that the
                // server will mark us as offline once we lose connection.
                userStatusDatabaseRef.set(isOnlineForDatabase);
            });
        });
    }

}
