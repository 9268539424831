import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { NotificationService } from "../../utils/notification.service";
import { UserService } from '../../user/user.service';
import { isString } from 'util';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { DashboardService } from 'app/+merchant/+dashboard/+services/dashboard.service';
import { I18nService } from '../../i18n/i18n.service';

declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {

  public storeId: string;
  public storeReady: boolean;
  public unseenMessageCount: number = 0;
  public firebaseQuerySubscriber: Subscription;
  public countryList: any = [];
public langFlag = "en";
  constructor(private router: Router,
    private notificationService: NotificationService,
    private angularDatabase: AngularFirestore,
    private translate: TranslateService,
    public afAuth: AngularFireAuth,
    public _userService: UserService,
    private dashboardService: DashboardService,
    private i18N: I18nService) {
  }

  ngOnInit() {
    this.storeId = this._userService.getCurrentUser().StoreID;
    if (this.storeId == '00000000-0000-0000-0000-000000000000')
      this.storeReady = false;
    else
      this.storeReady = true;
    this.firebaseAuthState();
     this.langFlag = this.i18N.currentLanguage;  
     this.i18N.getLanguageUpdates().subscribe((lang) => {
      this.langFlag = lang.language;
    })
    this.dashboardService.getCountryList().subscribe(res => {
      if(res.IsSuccess){
        this.countryList = res.Data;
      //  console.log("test",this.countryList);
        this.dashboardService.sendCountryList(this.countryList)
      }
    })
  }

  ngOnDestroy(): void {
    if (this.firebaseQuerySubscriber)
      this.firebaseQuerySubscriber.unsubscribe();
  }

  // logout() {
  //   this._userService.logout();
  //   this.router.navigate(['/']);
  // }

  searchMobileActive = false;

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }


  firebaseAuthState() {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.unSeenMessagesListner();
      } else {
        this.ngOnDestroy();
      }
    })
  }

  unSeenMessagesListner() {
    let query = this.angularDatabase.collection('users').doc(this.storeId);
    this.firebaseQuerySubscriber = query.snapshotChanges()
      .subscribe(querySnapshotData => {
        if (querySnapshotData) {
          let userData: any = querySnapshotData.payload.data();
          if (userData) {
            let count = userData.unseenMessagesCount || 0;
            this.unseenMessageCount = Number(count);
          }
        }
      })
  }

}
