import { element } from 'protractor';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { I18nService } from 'app/shared/i18n';
import { LanguageEnum } from 'app/app.common';
import { ProductService } from '../+services/product.service';
import { UtilMethods } from 'app/shared/utils/util-methods';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-add-variant',
  templateUrl: './add-variant.component.html',
  styleUrls: ['./add-variant.component.scss']
})
export class AddVariantComponent implements OnInit {

  getDefaultLang: any;
  public LanguageEnum = LanguageEnum;
  public modelVariant: any;
  public variantId: any;
  public separatorKeyCodes = [32, 9];
  public FieldErrors = false;
  public productId: any;
  public closeEvent: EventEmitter<any> = new EventEmitter<any>();
  modelVariantBackup;
  deletedOptionsEn = [];
  deletedOptionsAr = [];
  modelVarientOptionAr="";
  modelVarientOptionEn="";
  modelVariantTagInput = [];
  modelVariantTagColor= [];
  color= "#ffffff";
  isDisabledColor = true;

  constructor(private i18N: I18nService, private utilMethods: UtilMethods, private _translate: TranslateService, private _productService: ProductService, ) { }

  ngOnInit() {
    this.getDefaultLang = this.i18N.currentLanguage;
    this.i18N.getLanguageUpdates().subscribe(async (lang) => {
      this.getDefaultLang = lang.language;
    })
    if (!this.modelVariant) {
      this.modelVariant = {
        OptionsAr: [],
        NameAr: "",
        NameEn: "",
        OptionsEn: [],
        HasColor: false,
        ColorCode:[]
      }
    }
    else {      
      this.modelVariantBackup = Object.assign({}, this.modelVariant);
     // console.log(this.modelVariantBackup);
      this.modelVariantBackup.OptionsEn.forEach((element, i) => {
        if(this.modelVariantBackup.HasColor){
            this.modelVariantTagColor.push(this.modelVariantBackup.ColorCode[i]);
        }
          this.modelVariantTagInput.push(this.modelVariantBackup.OptionsEn[i] + "-"+this.modelVariantBackup.OptionsAr[i]) 
        
          this.modelVariant.OptionsEn=[];
          this.modelVariant.OptionsAr=[];
          this.modelVariant.ColorCode = [];
        });
        this.isDisabledColor = false;
    }
  }


  closeModal() {

    let data = {
      VariantNameEn: this.modelVariant.NameEn,
      VariantNameAr: this.modelVariant.NameAr,
      OptionNameEn: this.modelVariant.OptionsEn,
      OptionNameAr: this.modelVariant.OptionsAr,
      HasColor: this.modelVariant.HasColor,
      ColorCode: this.modelVariant.ColorCode
    }
    this.closeEvent.emit({ newVariant: data, id: this.variantId });

  }


  async submit() {
    this.FieldErrors = true;
    if (this.modelVariant.NameEn == "" || this.modelVariant.NameAr == "") {
      let msg = await this._translate.get('invalid_variant_name').toPromise<void>();
      this.utilMethods.notificationDataError(msg);
      return;
    }

    /* if ((this.modelVariant.OptionsEn == "" && this.modelVariantBackup.OptionsEn == "") 
      || (this.modelVariant.OptionsAr == "" && this.modelVariantBackup.OptionsAr == "" )) {
      let msg = await this._translate.get('invalid_variant_option').toPromise<void>();
      this.utilMethods.notificationDataError(msg);
      return;
    } */

    if (this.modelVariant.OptionsEn.length != this.modelVariant.OptionsAr.length) {
      let msg = await this._translate.get('invalid_variant_option_length').toPromise<void>();
      this.utilMethods.notificationDataError(msg);
      return;
    }

    if(this.modelVariantTagInput.length==0){
      let msg = await this._translate.get('invalid_variant_option_length').toPromise<void>();
      this.utilMethods.notificationDataError(msg);
      return;
    }

    this.modelVariant.OptionsAr.forEach((element, i) => {
      if (element.value)
        this.modelVariant.OptionsAr[i] = this.modelVariant.OptionsAr[i].value
    });

    this.modelVariant.OptionsEn.forEach((element, i) => {
      if (element.value)
        this.modelVariant.OptionsEn[i] = this.modelVariant.OptionsEn[i].value
    });
    if(this.modelVariant.HasColor == false){
      this.modelVariant.ColorCode = [];
    }

    let deleteVariant = 0;
   // console.log(this.variantId);
    if(this.variantId !=undefined){
      deleteVariant = 1;
    }else{
      deleteVariant =  this.deletedOptionsEn.length;
    }
    let params = {
      "ColorCode": this.modelVariant.ColorCode,
      "OptionNameAr": this.modelVariant.OptionsAr,
      "OptionNameEn": this.modelVariant.OptionsEn,
      "DeleteOptionNameEn": this.deletedOptionsEn,
      "DeleteOptionNameAr": this.deletedOptionsAr,
      "DeleteVariant":deleteVariant,
      "HasColor": this.modelVariant.HasColor,
      "productId": this.productId,
      "ProductImageId": 0,
      "productPrice": 0,
      "productQuantity": 0,
      "productVariantColors": [],
      "VariantNameAr": this.modelVariant.NameAr,
      "VariantNameEn": this.modelVariant.NameEn,
      "productVariantsAr": [],
      "productVariantsEn": [],
      "Id": this.variantId ? this.variantId : 0
    }


    this.addVariant(params)
  }

  addVariant(data) {
    this.utilMethods.showFullScreenLoader();
    this._productService.addProductVariant(data).subscribe(
      async () => {
        this.utilMethods.hideFullScreenLoader();
        let nameVariant = this.getDefaultLang == LanguageEnum.Arabic ? this.modelVariant.NameAr : this.modelVariant.NameEn;
        let msg = await this._translate.get('update_success').toPromise<void>();
        this.utilMethods.notificationSuccess("'" + nameVariant + "'" + msg);

        this.closeModal()
      },
      srError => {
        this.utilMethods.hideFullScreenLoader();
        this.utilMethods.notificationError(srError);
      }
    )
  }

  removeVariantOption(lang) {

    if (this.variantId) {
      if (lang == 'en') {
        for (var j = 0; j < this.modelVariantBackup.OptionsEn.length; j++) {
          if (this.modelVariant.OptionsEn.indexOf(this.modelVariantBackup.OptionsEn[j]) == -1)
            this.deletedOptionsEn.push(this.modelVariantBackup.OptionsEn[j]);
        }
      }
      else {
        for (var j = 0; j < this.modelVariantBackup.OptionsAr.length; j++) {
          if (this.modelVariant.OptionsAr.indexOf(this.modelVariantBackup.OptionsAr[j]) == -1)
            this.deletedOptionsAr.push(this.modelVariantBackup.OptionsAr[j]);
        }
      }

    }
  }
  removeVariantTag(element){
    //console.log(element);
    let removeItems = element.split("-",2);
    if (this.variantId) {
          if (this.modelVariantBackup.OptionsEn.indexOf(removeItems[0]) >= 0){
            this.deletedOptionsEn.push(removeItems[0]);
            this.deletedOptionsAr.push(removeItems[1]);
          }
    }
       let index= this.modelVariant.OptionsEn.indexOf(removeItems[0],0);        
        if(index > -1){
          this.modelVariant.OptionsEn.splice(index,1);
          this.modelVariant.OptionsAr.splice(index,1);
          this.modelVariant.ColorCode.splice(index,1);
        }    
}
  deleteVariantTag(element,index){
    setTimeout(() => { // fat arrow
     this.removeVariantTag(element); // Getting error this.functionTwo is not a function        
    }) ;
    this.modelVariantTagInput.splice(index,1); 
    this.modelVariantTagColor.splice(index,1);
  }
    addTagInput(){
      //console.log(this.modelVariantBackup);
     // debugger
      if(this.modelVariantBackup!= undefined){
        if (this.modelVariantBackup.OptionsEn.indexOf(this.modelVarientOptionEn) === -1 && this.modelVariantBackup.OptionsAr.indexOf(this.modelVarientOptionAr)
          && this.modelVariant.OptionsEn.indexOf(this.modelVarientOptionEn) === -1 && this.modelVariant.OptionsAr.indexOf(this.modelVarientOptionAr)){
          this.modelVariant.OptionsAr.push(this.modelVarientOptionAr);
          this.modelVariant.OptionsEn.push(this.modelVarientOptionEn);
          if(this.modelVariantBackup.HasColor){
            this.modelVariant.ColorCode.push(this.color);
            this.modelVariantTagColor.push(this.color);
          }
          this.modelVariantTagInput.push(this.modelVarientOptionEn+"-"+ this.modelVarientOptionAr);
          
        }
      }
      else{
        if(this.modelVariant.OptionsEn.indexOf(this.modelVarientOptionEn) === -1 && this.modelVariant.OptionsAr.indexOf(this.modelVarientOptionAr)){
          this.modelVariant.OptionsAr.push(this.modelVarientOptionAr);
          this.modelVariant.OptionsEn.push(this.modelVarientOptionEn);
          if(this.modelVariant.HasColor){
            this.modelVariant.ColorCode.push(this.color);
            this.modelVariantTagColor.push(this.color);
          }
          this.modelVariantTagInput.push(this.modelVarientOptionEn+"-"+ this.modelVarientOptionAr);
          
        }
      }
      

      this.modelVarientOptionEn = "";
      this.modelVarientOptionAr = "";
      this.color="#ffffff";
      //console.log(this.modelVariant.OptionsAr )
    }    
}
