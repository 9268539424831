import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/shared/user';
//import { LoginInfoComponent } from "../../user/login-info/login-info.component";


@Component({

    selector: 'sa-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
    public currentRoute;
    isHide:boolean = false;
    public subUserInfo: any;

    constructor( private router: Router,private _userService:UserService) {

        this.currentRoute = router;
        this.subUserInfo = this._userService.getCurrentUser(); 

        this.isHide = this.subUserInfo.LicSourceid == '10'? true:false; 
    }

    ngOnInit() {
    }

}
