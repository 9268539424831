import { element } from 'protractor';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { ProductService } from '../+services/product.service';

@Component({
  selector: 'app-list-variant-images',
  templateUrl: './list-variant-images.component.html',
  styleUrls: ['./list-variant-images.component.scss']
})
export class ListVariantImagesComponent implements OnInit {

  constructor(private productService: ProductService) { }

  image: any ;
  productImages: any;
  productId: any;
  error: boolean = false;
  public closeEvent: EventEmitter<any> = new EventEmitter<any>();
  ngOnInit() {
    this.getProductImages();
  }


  getProductImages() {
    this.productService.GetProductImages(this.productId).subscribe(res => {
        let productImages = res.Data
        productImages.forEach((item, i) => {
          item['active'] = false
        })
        this.productImages = productImages
       // console.log(this.productImages)
    }
    )
  }
  setImage(item) {
    this.productImages.forEach((element, i) => {
      if(element != item){
      element['active'] = false;
      }
    });
   
   this.image = item;


  
  // console.log(item)
  }

  submit() {
    if(!this.image)
      this.error = true;
    this.closeEvent.emit({ imageId: this.image.ImageId, imageUrl: this.image.ImageUrl });
  }

  closeModal() {
    this.error = false
    this.closeEvent.emit({})
  }
}
