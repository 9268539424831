import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormControl, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from './message.service';

@Injectable()
export class UtilMethods {


  constructor(private toastr: ToastrService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService) {

  }

  showLoader(name?) {
    if (name)
      this.spinner.show(name);
    else
      this.spinner.show();
  }

  hideLoader() {
    this.spinner.hide();
  }

  isUrl(str) {
    var urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    var url = new RegExp(urlRegex, 'i');
    if (str)
      return str.length < 2083 && url.test(str);
    else
      return false;
  }

  showFullScreenLoader() {
    this.spinner.show('MAIN_APP_LOADER');
  }

  hideFullScreenLoader() {
    this.spinner.hide('MAIN_APP_LOADER');
  }

  markControlsTouched(group: any) {
    for (let i in group.controls) {
      group.controls[i].markAsTouched();
      if (group.controls[i] instanceof FormGroup || group.controls[i] instanceof FormArray) {
        this.markControlsTouched(group.controls[i]);
      }
    }
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  emailValidator = (email) => {
    if (!email)
      return false;
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  passwordsMatch(password: string, confirmedPassword: string) {

    return (control: FormControl): { [s: string]: boolean } => {
      if (password !== confirmedPassword) {
        return { 'passwordMismatch': true }
      } else {
        //it always gets here no matter what
        return null;
      }
    }
  }

  notificationSuccess(msg, title?) {
    this.toastr.success(msg, title);
  }

  notificationError(msg, title?) {
   // console.log(msg);
    this.toastr.error(msg, title);
  }

  notificationDataError(msg, title?) {
    this.toastr.error(msg, title);
  }
}
