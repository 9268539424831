import { NgModule } from "@angular/core";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { MomentPipe } from "./moment.pipe";
import { TimeDirective } from "./time.directive";
import { FieldFilterPipe } from './field-filter.pipe';
import { BodyService } from "./body.service";
import { NotificationService } from "./notification.service";
import { ToggleActiveDirective } from "./toggle-active.directive";
import { ImagePreloadDirective } from "./image-preload.directive";
import { UtilMethods } from './util-methods';
import { DynamicScriptLoaderService } from './fileLoader.service';
import { MessageService } from './message.service';
import { NumFormat } from './numFormat.pipe';
import { ReadMoreComponent } from "./readMore";
import { TranslateModule } from '@ngx-translate/core';
import {ExcelService} from './excel.service';

@NgModule({
  declarations: [ToggleActiveDirective,ImagePreloadDirective, MomentPipe, TimeDirective,  FieldFilterPipe,SafeHtmlPipe, NumFormat, ReadMoreComponent],
  exports: [ToggleActiveDirective,ImagePreloadDirective, MomentPipe, TimeDirective,FieldFilterPipe, NumFormat,SafeHtmlPipe, ReadMoreComponent],
  providers: [BodyService, NotificationService, UtilMethods, DynamicScriptLoaderService, MessageService, ExcelService],
  imports: [TranslateModule]
})
export class UtilsModule {
  static forRoot() {
    return {
      ngModule: UtilsModule,
      providers: [BodyService, NotificationService,ExcelService]
    }
  }
}
