import { Injectable } from '@angular/core';
import { Subject ,  BehaviorSubject ,  Observable } from 'rxjs';
import { User } from 'app/shared/user';
import { Router } from '@angular/router';
import { Configuration } from '../../app.constant';
import { HttpClient } from '@angular/common/http';
import { ChatPaginationService } from '../../+merchant/chats/chat.service';
import { I18nService } from '../i18n/i18n.service';
import { AppConfigService } from 'app/app-config.service';


interface ICache { [key: string]: BehaviorSubject<any>; }
type serializable = object | Object;

@Injectable({ providedIn: 'root' })
export class UserService {

    private errorMsg: string = "Some error occurred !!";
   // public refreshSession: string;
    public user: Subject<any>;
    public UserInfoDetails: BehaviorSubject<User>;
 //   public imageRoute: string;
  //  public storeImageRoute: string;
   // public productImageRoute: string;
  //  public userInfo: string;
    private cache: ICache;
    private webAPIUrl:any;

    private merchantId = new BehaviorSubject('');
    currentMerchantId = this.merchantId.asObservable();
    private storeId = new BehaviorSubject('');
    currentStoreId = this.storeId.asObservable();
    private catId = new BehaviorSubject('');
    currentMainCatId = this.catId.asObservable();
    
    constructor(private _router: Router,
        private _http: HttpClient,
        private i18N: I18nService,
        private chatService: ChatPaginationService,
        private _configuration: Configuration,
        private config:AppConfigService) {
       // this.refreshSession = `${_configuration.getConfig().WEB_API}api/merchant/UserDetail`;
       // this.imageRoute = _configuration.API_BASEURL + 'assets/img/store/';
      //  this.storeImageRoute = _configuration.API_BASEURL + 'assets/img/store/';
       // this.productImageRoute = _configuration.getConfig().WEB_API + 'UploadFiles/Products/';
       // this.userInfo = `${_configuration.getConfig().WEB_API}api/user/SocialAuth`;
        this.cache = Object.create(null);
        this.UserInfoDetails = new BehaviorSubject(JSON.parse(localStorage.getItem('adminLScurrentUser')));

    }

    getUpdatedInfo() {
        let user: any = this.getCurrentUser();
        this.getUpdatedSession(user.UserID)
            .subscribe((res: any) => {
                if (res.IsSuccess) {
                    this.setCurrentUser(res.Data);
                }
            })
    }

    public get currentUserValue(): User {
        return this.UserInfoDetails.value;
    }

    setItem<T extends serializable>(key: string, value: T): BehaviorSubject<T> {
        localStorage.setItem(key, JSON.stringify(value));

        if (this.cache[key]) {
            this.cache[key].next(value);
            return this.cache[key];
        }

        return this.cache[key] = new BehaviorSubject(value);
    }

    getItem<T extends serializable>(key: string): BehaviorSubject<T> {
        if (this.cache[key])
            return this.cache[key];
        else
            return this.cache[key] = new BehaviorSubject(JSON.parse(localStorage.getItem(key)));
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
        if (this.cache[key])
            this.cache[key].next(undefined);
    }

    setCurrentUser(user: any) {
        this.UserInfoDetails.next(user);
        this.setItem('adminLScurrentUser', user);
    }

    getCurrentUser() {
        let temp: any = this.getItem('adminLScurrentUser').value;
        let allData: User;
        if (temp)
            allData = this.UserInfoDetails.value;
        return allData;
    }

    checkAuthorization(): boolean {

        let temp: any = this.getItem('adminLScurrentUser').value;
        if (!temp)
            return false;
        let allData: User;
        if (temp)
            allData = this.UserInfoDetails.value;
        if (allData.MerchantID == "00000000-0000-0000-0000-000000000000" || allData.MerchantID == null) {

            this._router.navigate(['']);
            return false;
        }
        else if (allData.StoreID == "00000000-0000-0000-0000-000000000000" || allData.StoreID == null) {
            return true;
        }
        return true;
    }

    logout() {
        try {
            if (this.getCurrentUser())
                this.chatService.removeToken(this.getCurrentUser().StoreID, this.i18N.currentLanguage);
        } catch (e) { console.log(e) }
        this.removeItem('adminLScurrentUser');
        this.UserInfoDetails = new BehaviorSubject<any>(null);
    }

    getUpdatedSession(userId) : Observable<any>{
        return this
            ._http.get(this.config.getConfig().WEB_API +'api/merchant/UserDetail?UserId=' + userId);
    }
    getUserDetails(userId) {
        return this
            ._http.get(this.config.getConfig().WEB_API +'api/user/SocialAuth?SocialId=' + userId);
    }

    changeMerchantId(id: any) {
        this.merchantId.next(id);
        localStorage.setItem('adminMerID',id);
      }

      changeStoreId(id: any) {
        this.storeId.next(id);
        localStorage.setItem('adminStoreID',id);
      }
      changeStoreCatId(id: any) {
        this.catId.next(id);
        localStorage.setItem('adminCatID',id);
      }
}