import { Component, OnInit } from '@angular/core';
import { I18nService } from 'app/shared/i18n';
import { AppConfigService } from './app-config.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    template:
        `<ngx-spinner name="MAIN_APP_LOADER" fullScreen="false" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff"
            type="ball-scale-multiple">
            <p style="font-size: 20px; color: white"></p>
        </ngx-spinner>
        <router-outlet  ></router-outlet>`
})
export class AppComponent implements OnInit {

    public getDefaultLang: string;

    public constructor(private i18N: I18nService, private configService: AppConfigService,
        private router:Router) {
        //        console.log('config', configService.getConfig().WEB_API);
        setTimeout(() => {
            $('.loaderWrap').fadeOut();
        }, 500);
        //if(configService.getConfig().ProjectName == 'Fazaa'){
         //   this.router.navigate(['/merchant/shop']);
        //}

    }

    ngOnInit() {

        this.getDefaultLang = this.i18N.currentLanguage;
        this.i18N.getLanguageUpdates().subscribe((lang) => {
            this.getDefaultLang = lang.language;
        })



    }


}