import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../shared/user/user.service';

@Injectable({ providedIn: 'root' })
export class CheckAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: UserService
    ) { }

    canActivate(route: ActivatedRouteSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (!currentUser) {
            return true;
        }


        this.router.navigate(['/merchant/dashboard']);
        return false;
    }
}