import { Injectable } from '@angular/core';
import { UserService } from '../../../shared/user';
import { Router, ActivatedRoute, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET } from '@angular/router';
@Injectable({
    providedIn: 'root'
})

export class NavigationService {
    public subUserInfo: any;
    constructor(private router: Router, private _userService: UserService) {
        this.subUserInfo = this._userService.getCurrentUser(); 
     }

    activateRouterLink(routerUrl, subMenuId) {
       
        if (this.subUserInfo.StoreID !== "00000000-0000-0000-0000-000000000000") {
            const tree: UrlTree = this.router.parseUrl(routerUrl);
            const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
            const s: UrlSegment[] = g.segments;
            let pathId = "#" + s[1].path;
            if (document.querySelector(pathId).classList.value != 'active') {
                if ($('aside nav ul li').hasClass('open')) {
                    $('aside nav ul li').removeClass('open')
                    $('aside nav ul li ul').css("display", "none");
                }
                $('aside nav ul li').removeClass('active')
                $("aside " + pathId).addClass("open");
                $("aside " + pathId + ' ul').css("display", "block");
                $("aside " + pathId + ' ul  li:nth-child(' + subMenuId + ')').addClass("active");
            }
            else {
                if (document.querySelector(pathId + ' ul  li:nth-child(' + subMenuId + ')').classList.value != 'active')
                    $("aside " + pathId + ' ul  li:nth-child(' + subMenuId + ')').addClass("active");
            }
        }
    }
}