import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NotificationService } from "../../utils/notification.service";
import { UserService } from '../user.service';
import { isString } from 'util';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'sa-logout',
    templateUrl: 'logout.component.html',
    styleUrls: ['logout.component.css'],
})
export class LogoutComponent implements OnInit {

    public user: any;

    constructor(private router: Router,
        private notificationService: NotificationService,
        private translate: TranslateService,
        public _userService: UserService) {
    }

    async showPopup() {
        let msgs: any = await this.translate.get(['logout', 'logout_msg', "yes", "no"]).toPromise<void>();
        this.notificationService.smartMessageBox({
            title: "<i class='fa fa-sign-out txt-color-orangeDark'></i> " + msgs.logout + " <span class='txt-color-orangeDark'><strong>" + $('#show-shortcut').text() + "</strong></span> ?",
            content: msgs.logout_msg,
            buttons: `[${msgs.no}][${msgs.yes}]`

        }, (ButtonPressed) => {
            if (ButtonPressed == msgs.yes) {
                this.logout()
            }
        });
    }

    logout() {
        this._userService.logout();
        this.router.navigate(['/auth/login']);
    }

    ngOnInit() {
        this._userService.UserInfoDetails
            .subscribe((data: any) => {
                if (isString(data))
                    this.user = JSON.parse(data);
                else
                    this.user = data;
            });
    }



}
