import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { I18nService } from 'app/shared/i18n';
import { LanguageEnum } from 'app/app.common';


@Component({
    selector: 'modal-content',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

    public getDefaultLang: string;
    public LanguageEnum = LanguageEnum;

    constructor(public bsModalRef: BsModalRef,
        private i18N: I18nService) { }

    ngOnInit() {
        this.getDefaultLang = this.i18N.currentLanguage;
        this.i18N.getLanguageUpdates().subscribe((lang) => {
            this.getDefaultLang = lang.language;
        })
    }

    hideTermsDialog() {
        this.bsModalRef.hide();
    }

}