import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/platform-browser";

@Injectable()
export class Configuration {

    public API_BASEURL: string;
    public WebApi: string;
    public Web: string;
    public url: string = '';

    constructor(@Inject(DOCUMENT) private document) {
        let domain = document.location.hostname.split(':')[0];
        this.url = document.location.protocol + '//' + domain;
        //  this.WebApi = `${this.url}:56234/`;
        this.WebApi = `http://localhost:56234/`;
        this.API_BASEURL = `http://localhost:56234/`;
       // this.WebApi = `https://stagingadmin.akshaak.com/v1/`;
       // this.API_BASEURL = `https://stagingadmin.akshaak.com/`;
      
    // this.WebApi = `https://admin.akshaak.com/v2/`;
    // this.API_BASEURL = `https://admin.akshaak.com/`;
        //this.WebApi = `http://buyer.akshaak.com/v1/`;
        //this.API_BASEURL = `http://beta.akshaak.com/`;
        
       // this.WebApi = `https://demoadmin.akshaak.com/v1/`;
       // this.API_BASEURL = `https://demoadmin.akshaak.com/`;
        
    }
}